'use strict';

angular.module('managerApp').filter('capitalize', function () {
    return function (input) {
        if (!input) {
            return input;
        }
        var words = input.toLowerCase().split(' ');
        var capitalizedWords = words.map(function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return capitalizedWords.join(' ');
    };
});